
*{  
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
} 

.app{

  body, html {
    overflow-x: hidden; /* Prevent horizontal scrolling */
  }
  
  .loginregisterbody{
    background: url("./LPimages/blue2.jpeg");
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    overflow: hidden;

    
  
   
    .registerBtn{
      margin-left: 15px;
  }
   .btn.white-btn{
       background: rgba(255, 255, 255, 0.7);
   }
   .btn.btn.white-btn:hover{
       background: hsl(128, 94%, 45%);
   }
   
   .wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 110vh;
    background: rgba(39, 39, 39, 0.4);
 }


   .form-box{
       position: relative;
       display: flex;
       align-items: center;
       justify-content: center;
       width: 512px;
       height: 80vh;
       overflow: hidden;
       z-index: 2;
   }
   .login-container{
       position: absolute;
       left: 4px;
       width: 500px;
       display: flex;
       flex-direction: column;
       transition: .5s ease-in-out;
   }
   .register-container{
       position: absolute;
       left: 4px;
       width: 500px;
       display: flex;
       flex-direction: column;
       transition: .5s ease-in-out;
   }

   .top span{
       color: #ffffff;
       font-size: small;
       padding: 10px 0;
       display: flex;
       justify-content: center;
   }
   .top span a{
       font-weight: 500;
       color: hsl(0, 0%, 96%);
       margin-left: 5px;
   }
   .top h2{color: #ffffff;
    font-size: 30px;
    text-align: center;
    padding: 10px 0 30px 0;
    
}
   .two-forms{
       display: flex;
       gap: 10px;
   }
   .input-field{
       margin-top: 10px;
       margin-bottom: 10px;
       font-weight: 700;
       font-size: 15px;
       background: rgba(255, 255, 255, 0.2);
       color: #000000;
       height: 45px;
       width: 100%;
       padding: 0 10px 0 45px;
       border: none;
       border-radius: 30px;
       outline: none;
       transition: .2s ease;
   }
   .input-field:hover, .input-field:focus{
       background: rgba(255, 255, 255, 0.25);
   }
   ::-webkit-input-placeholder{
       color: #fff;
   }


   .input-box {
    position: relative;
  }
  
  .input-box input {
    width: 100%;
    padding-right: 60px; 
  }
  
  .input-box .toggle-password {
    position: absolute;
    right: 10px; 
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    color: #000000;
    font-weight: 700;
    cursor: pointer;
    font-size: 14px;
    outline: none;
    padding: 5px;
    transition: color 0.2s ease-in-out;
  }
  
  .input-box .toggle-password:hover {
    color: hsl(129, 74%, 85%);
  }



   .input-box i{
       position: relative;
       top: -35px;
       left: 17px;
       color: #000000;
   }
   .input-box .submit {
    display: flex; 
    justify-content: center;  
    align-items: center;  
    font-size: 15px;
    font-weight: 700;
    color: black;
    height: 45px;
    width: 100%;
    border: none;
    border-radius: 30px;
    outline: none;
    background: rgba(255, 255, 255, 0.918);
    cursor: pointer;
    transition: 0.3s ease-in-out;
    padding: 0;  /* Reset padding for centering */
  }
   .submit:hover{
       background: hsl(128, 94%, 45%);
       box-shadow: 1px 5px 7px 1px rgba(0, 0, 0, 0.2);
   }
   .two-col{
       display: flex;
       justify-content: space-between;
       color: #fff;
       font-size: small;
       margin-top: 10px;
   }
   .two-col .one{ 
       display: flex;
       gap: 5px;
   }
   .two-col .one label{ 
    color: #ffffff;
   }
   .two label a{
       text-decoration: none;
       color: #fff;
   }
   .two label a:hover{
       text-decoration: underline;
   }
   .login-message{
       display: flex;
       justify-content: center;
       font-family: 'Times New Roman', Times, serif;
       font-weight: 300;
   }
   
   .message.success {
    color: rgb(0, 165, 0);
  }
  
  .message.error {
    color: rgb(255, 0, 0);
  }
   @media (max-width: 600px) {
     .form-box{
       width: 400px;
       height: 80vh;
       z-index: 2;
   }
   .login-container{
    margin-left: 20%;
     left: 4px;
     width: 30vh;
   }
   .register-container{
     margin-left: 12%;
     left: 4px;
     width: 35vh;
   }
   .top span{
     
     font-size: 0.6em;
     padding: 5px 0;
   }
   
   header{
     font-size: 10px;
     padding: 10px 0 30px 0;
   }
   .input-field{
     margin-top: 5px;
     margin-bottom: 5px;
     font-weight: 500;
     font-size: 9px;
     height: 40px;
     width: 100%;
     padding: 0 10px 0 18px;
   }
   .submit{
     font-size: 7px;
     font-weight: 600;
     padding-left: 0vh;
     color: black;
     height: 20px;
     width: 100%;
   }
   .two-col{
     font-size: 0.6em;
     margin-top: 5px;
   }
   } 
   }
   


.homebody{
 
    
    
    
    li { list-style: none; }
    
    a { text-decoration: none; }
    
    a,
    img,
    span,
    button,
    ion-icon { display: block; }
    
    button {
      border: none;
      background: none;
      font: inherit;
      text-align: left;
      cursor: pointer;
    }
    
    address { font-style: normal; }
    
    ion-icon { pointer-events: none; }
    
    html {
      font-family: "Nunito Sans", sans-serif;
      scroll-behavior: smooth;
    }
    
    body {
      background: hsl(0, 0%, 100%);
      overflow-x: hidden;
    }
    
    ::-webkit-scrollbar {
      width: 10px;
      height: 8px;
    }
    
    ::-webkit-scrollbar-track { background: hsl(0, 0%, 100%); }
    
    ::-webkit-scrollbar-thumb {
      background: hsl(200, 45%, 65%);
      border-left: 2px solid hsl(0, 0%, 100%);
    }
    
    
    
    
    /*-----------------------------------*\
      #REUSED STYLE
    \*-----------------------------------*/
    
   
    
    button, a { transition: 0.25s ease; }
    
    .Hh1,
    .Hh2,
    .Hh3 {
      color: hsl(188, 63%, 7%);
      font-family: "Poppins", sans-serif;
      line-height: 1.3;
    }
    
    .Hh1 {
      font-size: 1.875rem;
      line-height: 1;
    }
    
    .Hh2 { font-size: 1.5rem; }
    
    .Hh3 {
      font-size: 1.125rem;
      font-weight:  700;
    }
    
    .Hh3 > a { color: inherit; }
    
    .Hbtn {
      position: relative;
      background: hsl(128, 94%, 45%);
      color: hsl(0, 0%, 100%);
      font-family: "Poppins", sans-serif;
      font-size: 0.875rem;
      text-transform: var(--text-transform, capitalize);
      border: 1px solid hsl(128, 94%, 45%);
      padding: 10px 20px;
      z-index: 1;
    }
    
    .Hbtn:is(:hover, :focus) {
      background: hsl(0, 0%, 0%);
      color: hsl(188, 63%, 7%);
      border-color: hsl(0, 0%, 0%);
    }
    
    .Hbtn::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 100%;
      background: hsl(0, 0%, 100%);
      transition: 0.25s ease;
      z-index: -1;
    }
    
    .Hbtn:is(:hover, :focus)::before { width: 100%; }
    
    .Hw-100 {
      position: relative;
      left: 50%;
      top: 90vh;
      width: 50%;
      
    }
    
    .Hsection-subtitle {
      color: hsl(128, 94%, 45%);
      font-size: 0.875rem;
      font-weight: 600;
      padding: 5px 20px;
      background: hsla(9, 100%, 62%, 0.1);
      width: max-content;
      border-radius: 50px;
      margin-inline: auto;
      margin-bottom: 15px;
     
    }
    
    .Hsection-title {
      text-align:  center;
      margin-bottom:  50px;
    }
    
    .Hcard-badge {
      background: hsl(0, 0%, 0%);
      color: hsl(0, 0%, 100%);
      font-size: 0.75rem;
      text-transform: uppercase;
      position: absolute;
      top: 15px;
      right: 15px;
      padding: 4px 10px;
    }
    
    .Hcard-badge.green { background: hsl(89, 72%, 45%); }
    
    .Hcard-badge.orange { background: hsl(128, 94%, 45%); }
    
    .Hhas-scrollbar {
     display: grid;
     grid-template-columns: 1fr 1fr 1fr 1fr;
     margin-left: 5px;
     column-gap: 5px;
    }
    
    
    
    .Hhas-scrollbar::-webkit-scrollbar-track {
      background: hsl(192, 24%, 96%);
      outline: 2px solid hsl(200, 15%, 43%);
      border-radius: 10px;
    }
    
    .Hhas-scrollbar::-webkit-scrollbar-thumb {
      background: hsl(200, 15%, 43%);
      border: 1px solid hsl(192, 24%, 96%);
      border-radius: 10px;
    }
    
    .Hhas-scrollbar::-webkit-scrollbar-button { width: 15%; }
    
    
    .Hcontainer { padding-inline: 25px; }
    
    
    
    /*-----------------------------------*\
      #HEADER
    \*-----------------------------------*/
    
    .Hheader {
      position: relative;
      z-index: 2;
    }
    
    .Hheader-top {
      background: hsl(200, 69%, 25%);
      padding-block: 15px;
    }
    
    .Hheader-top .Hcontainer,
    .Hheader-top-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
    }
    
    .Hheader-top .Hcontainer { gap: 8px 20px; }
    
    .Hheader-top-list { gap: 15px; }
    
    .Hheader-top-link {
      color: hsl(0, 0%, 100%);
      font-size: 0.813rem;
      font-weight: 700;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
    }
    
    .Hheader-top-link:is(:hover, :focus) { color: hsl(128, 94%, 45%); }
    
    .Hheader-top-link ion-icon {
      color: hsl(128, 94%, 45%);
      font-size: 15px;
      --ionicon-stroke-width: 60px;
    }
    
    .Hheader-top .Hwrapper,
    .Hheader-top-social-list {
      display: flex;
      align-items: center;
    }
    
    .Hheader-top .Hwrapper { gap: 20px; }
    
    .Hheader-top-social-list { gap: 8px; }
    
    .Hheader-top-social-link {
      color: hsl(0, 0%, 100%);
      font-size: 15px;
    }
    
    .Hheader-top-btn {
      background: hsl(0, 100%, 51%);
      color: hsl(0, 0%, 100%);
      font-size: 0.813rem;
      font-weight: 700;
      padding: 4px 15px;
    }
    
    .Hheader-top-btn:is(:hover, :focus) { --orange-soda: hsl(128, 94%, 45%); }
    
    .Hheader-bottom {
      background: hsl(0, 0%, 100%);
      
    }
    
    .Hlogo {
      width: 70px;
      height: 70px;
      border-radius: 70px;
      margin-inline: auto;
     }
    
    
    .Hnavbar {
      background: hsl(0, 0%, 100%);
      position: fixed;
      top: 0;
      left: -310px;
      max-width: 300px;
      width: 100%;
      height: 100%;
      box-shadow: 0 3px 10px hsla(0, 0%, 0%, 0.3);
      z-index: 5;
      padding: 60px 20px;
      visibility: hidden;
      transition: 0.15s ease-in;
    }
    .navbar-list {
      display: flex;
    justify-content: center;
      gap: 3px;
    }
    
    .Hnavbar.Hactive {
      visibility: visible;
      transition: 0.25s ease-out;
    }
    
    .Hnavbar-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 40px;
      border-bottom: 1px solid hsl(0, 0%, 93%);
      margin-bottom: 25px;
    }
    
    .Hnavbar-top .Hlogo img { width: 200px; }
    
    .Hnav-close-btn ion-icon {
      font-size: 20px;
      --ionicon-stroke-width: 45px;
      padding: 5px;
    }
    
    .Hnavbar-link {
      color: hsl(200, 15%, 43%);
      font-size: 0.875rem;
      font-weight: 600;
      text-transform: uppercase;
      margin-left: 10vh;
    }
    
    .Hnavbar-link:is(:hover, :focus) { color: hsl(128, 94%, 45%); }
    
    .Hoverlay {
      position: fixed;
      inset: 0;
      background: hsla(0, 0%, 0%, 0.7);
      opacity: 0;
      pointer-events: none;
      transition: 0.25s ease;
      z-index: 4;
    }
    
    .Hoverlay.active {
      opacity: 1;
      pointer-events: all;
    }
    
    .Hheader-bottom-actions {
      background: hsl(0, 0%, 100%);
      left: 0;
      width: 100%;
      display: flex;
       justify-content: space-evenly;
      padding-block: 15px 10px;
        box-shadow: -2px 0 30px hsla(237, 71%, 52%, 0.2);
      z-index: 3;
    }
    
    
    
    
    
    .Hheader-bottom-actions-btn-profile ion-icon {
      color: hsl(0, 0%, 10%);
      font-size: 20px;
      margin-inline: auto;
      margin-bottom: 5px;
      --ionicon-stroke-width: 40px;
      transition: 0.25s ease;
    }
    
    /* Style for the user details popup */
    .Hpopup {
      display: none;
      position: fixed;
      top: 20%;
      left: 80%;
      transform: translate(-50%, -50%);
      background-color: hsl(128, 94%, 45%);
      padding: 20px;
      border: 1px solid #ccc;
      border-radius: 5px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      z-index: 1000;
    }
    
    
    .Huserclose{
      margin-top: 10px;
      border: rgb(0, 0, 0) solid;
      border-radius: 7px;
    }
    
    .Hheader-bottom-actions-btn:is(:hover, :focus) ion-icon { color: hsl(128, 94%, 45%); }
    
    .Hheader-bottom-actions-btn span {
      color: hsl(200, 15%, 43%);
      font-family: "Poppins", sans-serif;
      font-size: 0.75rem;
      font-weight: 500;
    }
    
    
    
    
    
    /*-----------------------------------*\
      #HERO
    \*-----------------------------------*/
    
    .Hhero {
      background: hsl(192, 24%, 96%);
      padding-block: 60px;
    }
    
    .Hhero-content { margin-bottom: 60px; }
    
    .Hhero-subtitle {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 5px;
      margin-bottom: 20px;
    }
    
    .Hhero-subtitle ion-icon { color: hsl(128, 94%, 45%); }
    
    .Hhero-subtitle span {
      color: hsl(188, 63%, 7%);
      font-size: 0.875rem;
      font-weight: 700;
    }
    
    .Hhero-title { margin-bottom: 20px; }
    
    .Hhero-text {
      color: hsl(200, 15%, 43%);
      font-size: 0.875rem;
      line-height: 1.8;
      padding-left: 15px;
      border-left: 1px solid;
      margin-bottom: 30px;
    }
    
    
    
    
    
    /*-----------------------------------*\
      #ABOUT
    \*-----------------------------------*/
    
    .Habout {
      margin-top: -38vh;
      padding-block: 100px; }
    
    .Habout-banner {
      position: relative;
      left: 100vh;
    }
    
    .Habout-banner > img {
      max-width: max-content;
      width: 100%;
    }
    
    .Habout-banner .Habs-img {
      position: absolute;
      bottom: 100px;
      left: 15px;
      width: 50%;
      border-radius: 4px;
    }
  
    
     .Habout.Hsection-subtitle {
     margin-inline: 0; }
    
    .Habout .Hsection-title {
      --text-align: center;
      --margin-bottom: 15px;
    }
    
    .Habout-text {
      color: hsl(200, 15%, 43%);
      font-size: 0.875rem;
      line-height: 1.7;
      margin-bottom: 30px;
    }
    
    .Habout-list { margin-bottom: 30px; }
    
    .Habout-item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
      margin-bottom: 15px;
    }
    
    .Habout-item-icon {
      background: hsl(7, 56%, 91%);
      height: 45px;
      min-width: 45px;
      border-radius: 50%;
      display: grid;
      place-items: center;
    }
    
    .Habout-item-icon ion-icon {
      color: hsl(128, 94%, 45%);
      font-size: 18px;
    }
    
    .Habout-item-text {
      color: hsl(200, 15%, 43%);
      font-size: 0.875rem;
    }
    
    .Habout .Hcallout {
      background: hsla(7, 78%, 53%, 0.05);
      color: hsl(200, 15%, 43%);
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 1.8;
      padding: 20px 25px;
      border-left: 4px solid hsl(128, 94%, 45%);
      margin-bottom: 40px;
    }
    
    .Habout .Hbtn {
      max-width: max-content;
      --text-transform: uppercase;
    }
    
    
    
    
    
    /*-----------------------------------*\
      #SERVICE
    \*-----------------------------------*/
    
    .Hservice {
      background: hsl(192, 24%, 96%);
      padding-block: 100px;
    }
    
    .Hservice-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 30px;
    }
    
    .Hservice-card {
      position: relative;
      background: hsl(0, 0%, 100%);
      text-align: center;
      padding: 40px 30px;
      box-shadow: 0 16px 32px hsla(188, 63%, 7%, 0.1);
    }
    
    .Hservice-card .Hcard-icon {
      width: max-content;
      margin-inline: auto;
      margin-bottom: 20px;
    }
    
    .Hservice-card .Hcard-title { margin-bottom: 15px; }
    
    .Hservice-card .Hcard-title > a:is(:hover, :focus) { color: hsl(128, 94%, 45%); }
    
    .Hservice-card .Hcard-text {
      color: hsl(200, 15%, 43%);
      font-size: 0.875rem;
      line-height: 1.8;
      margin-bottom: 25px;
    }
    
    .Hservice-card .Hcard-link {
      color: var(--opal);
      font-size: 0.875rem;
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
    }
    
    .Hservice-card:is(:hover, :focus) .Hcard-link { color: hsl(128, 94%, 45%); }
    
    .Hservice-card::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0;
      height: 4px;
      background: hsl(128, 94%, 45%);
      transition: 0.25s ease;
    }
    
    .Hservice-card:is(:hover, :focus)::after { width: 100%; }
    
    
    .Hcard-icon .forex-logo{
      width: 10vh;
      height: 10vh;
    }
    
    
    
    
    
    
    /*-----------------------------------*\
      #FEATURES
    \*-----------------------------------*/
    
    .Hfeatures {
      background: hsl(192, 24%, 96%);
      padding-block: 100px;
    }
    
    .Hfeatures-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 50px 20px;
    }
    
    .Hfeatures-list > li { width: calc(50% - 10px); }
    
    .Hfeatures-card {
      position: relative;
      background: hsl(0, 0%, 100%);
      padding: 40px 15px;
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      align-items: center;
      gap: 10px;
      box-shadow:  0 5px 20px 0 hsla(219, 56%, 21%, 0.1);
    }
    
    .Hfeatures-card:is(:hover, :focus) { background: hsl(128, 94%, 45%); }
    
    .Hfeatures-card .Hcard-icon {
      background: hsl(8, 100%, 97%);
      color: hsl(128, 94%, 45%);
      width: 60px;
      height: 60px;
      display: grid;
      place-items: center;
      border-radius: 50%;
      font-size: 28px;
    }
    
    .Hfeatures-card .Hcard-icon ion-icon { --ionicon-stroke-width: 20px; }
    
    .Hfeatures-card .Hcard-title {
      color: hsl(188, 63%, 7%);
      font-family: "Poppins", sans-serif;
      font-size: 0.813rem;
      font-weight: 600;
      text-align: center;
      transition: 0.25s ease;
    }
    
    .Hfeatures-card:is(:hover, :focus) .Hcard-title { color: hsl(0, 0%, 100%); }
    
    .Hfeatures-card .Hcard-btn {
      background: hsl(0, 0%, 100%);
      color: hsl(200, 15%, 43%);
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 50%);
      width: 40px;
      height: 40px;
      display: grid;
      place-items: center;
      font-size: 20px;
      border-radius: 50%;
      box-shadow: 0 0 10px hsla(219, 56%, 21%, 0.1);
      transition: 0.25s ease;
    }
    
    .Hfeatures-card:is(:hover, :focus) .Hcard-btn { color: hsl(128, 94%, 45%); }
    
    
    
    
    
    
    
    
    /*-----------------------------------*\
      #CTA
    \*-----------------------------------*/
    
    .Hcta {
      background: linear-gradient(to bottom, hsl(0, 0%, 100%) 50%, hsl(229, 17%, 19%) 50%);
    }
    
    .Hcta-card {
      background: hsl(128, 94%, 45%);
      padding: 50px 25px;
      box-shadow: 0 16px 32px hsla(188, 63%, 7%, 0.1);
    }
    
    .Hcta-card .Hcard-content {
      max-width: max-content;
      margin-inline: auto;
      margin-bottom: 30px;
    }
    
    .Hcta-card .Hcard-title {
      color: hsl(0, 0%, 100%);
      line-height: 1.3;
      margin-bottom: 15px;
    }
    
    .Hcta-card .Hcard-text {
      color: hsl(0, 0%, 100%);
      font-size: 0.875rem;
      line-height: 1.8;
    }
    
    .Hcta-btn {
      display: flex;
      align-items: center;
      gap: 10px;
      color: hsl(0, 0%, 0%);
      background: hsl(0, 0%, 100%);
      box-shadow: 0 16px 32px hsla(188, 63%, 7%, 0.1);
      border-color: hsl(0, 0%, 100%);
      margin-inline: auto;
    }
    
    .Hcta-btn:is(:hover, :focus) {
      background: none;
      color: hsl(0, 0%, 100%);
      border-color: transparent;
    }
    
    .Hcta-btn::before { background: hsl(0, 0%, 0%); }
    
    
    
    
    
    /*-----------------------------------*\
      #FOOTER
    \*-----------------------------------*/
    
    .Hfooter {
      background: hsl(229, 17%, 19%);
      color: hsl(0, 0%, 100%);
      margin-bottom: 68px;
    }
    
    .Hfooter .Hcontainer {
      display: flex;
      justify-content: space-evenly;
      padding-inline: 30px;

     }
    
    .Hfooter a { color: inherit; }
    
    .Hfooter-top {
      padding-top: 80px;
      padding-bottom: 40px;
    }
    
    .Hfooter-brand { margin-bottom: 70px; }
    
    .Hfooter-brand .logo { margin-bottom: 15px; }
    
    .Hsection-text {
      font-size: 0.875rem;
      line-height: 1.8;
      margin-bottom: 30px;
      max-width: 45ch;
    }
    
    .Hcontact-list {
      display: grid;
      gap: 15px;
      margin-bottom: 30px;
    }
    
    .Hcontact-link {
      display: flex;
      align-items: flex-start;
      gap: 10px;
    }
    
    .Hcontact-link ion-icon { font-size: 18px; }
    
    .Hcontact-link :is(address, span) {
      font-size: 0.875rem;
      transition: 0.25s ease;
    }
    
    .Hcontact-link:is(:hover, :focus) span { color: hsl(128, 94%, 45%); }
    
    .Hsocial-list {
      display: flex;
      align-items: center;
      gap: 20px;
    }
    
    .Hfooter-list:not(:last-child) { margin-bottom: 50px; }
    
    .Hfooter-list-title {
      font-family: "Poppins", sans-serif;
      font-size: 1.375rem;
      font-weight: 700;
      margin-bottom: 15px;
    }
    
    .Hfooter-link {
      font-size: 0.875rem;
      padding-block: 10px;
    }
    
    .Hfooter-link:is(:hover, :focus) { color: hsl(128, 94%, 45%); }
    
    .Hfooter-bottom {
      background:  hsl(89, 72%, 45%);
      padding-block: 25px;
    }
    
    .Hcopyright {
      font-size: 0.875rem;
      text-align: center;
    }
    
    .Hcopyright a { display: inline-block; }
    
    .Hcopyright a:is(:hover, :focus) { color: hsl(128, 94%, 45%); }
    



    @media (max-width: 600px) {

      .homebody {
        overflow-x: hidden; /* Prevent horizontal scrolling */
      }

      .Hw-100 {
        position: relative;
        left: 50%;
        top: 2vh;
        width: 45%;
      }
    
      .Hh2 { font-size: 1rem; }
      
      .Hheader-top .Hcontainer { gap: 5px 4px; }
    
      .Habout-banner {
        position: relative;
        right: 0vh;
        top: -2vh;
       
      }
    
      .homebody {
        padding: 15px;
      }
    
      .Hheader {
        align-items: flex-start;
      }
    
      .Hheader-top, .Hheader-bottom {
        flex-direction: row;
      }
    
      .Hheader-top-list, .Hheader-top-social-list, .Hnavbar, .Hproperty-list {
        flex-direction:row;
        align-items: flex-start;
      }
      .Hheader-top-link {
        font-size: 0.45rem;
        font-weight: 700;
        gap: 2px;
      }
      .Hheader-top-btn{
        font-size: 0.45rem;
        padding: 3px 12px;
      }
      .Hheader-top-social-link {
        font-size: 12px;
      }
      .Hheader-bottom-actions {
        flex-direction: row;
        height: 32px;
        width: 100%;
      }
    
      .Hheader-bottom-actions-btn ion-icon {
        font-size: 11px;
        margin-bottom: 2px;
        margin-top: -3px;
      }
      .Hheader-bottom-actions-btn span{
        font-size: 0.4rem;
      }
    
      .Hnavbar-link {
        color: hsl(200, 19%, 46%);
        font-size: 0.4rem;
        margin-left: 1vh;
      }
    
      .Hhero {
        background: hsl(192, 24%, 96%);
        padding-block: 20px;
      }
    
      .Hhero-content, .Hhero-banner {
        margin-top: 10px;
        width: 100%;
        
        
      }
    
      .Habout-banner {
        display: none;
      }
    
      .Hhero-title {
        font-size: 1em;
      }
      .Hhero-text {
        font-size: 0.65em;
      }
    
      .Hbtn {
        width: 50%;
        text-align: center;
        font-size: 0.65em;
      }
      .Hhero-content {
        margin-bottom: -85px;
      }
      .Habout-banner .Habs-img {
        display: none;
      }
      .Habout {
        margin-top: -5vh;
        padding-block: 60px; }

      .Habout-content {
        margin-left: 0%;
      }
      .Hsection-subtitle { 
        font-size: 0.85em;
      }
    
      .Habout .Hsection-title {
        margin-bottom: 12px;
      }
    
      .Habout-text {
        font-size: 0.5rem;
        line-height: 0.3;
        margin-bottom: 28px;
      }
      .Habout-item {
        gap: 2px;
        margin-bottom: 6px;
      }
      .Habout-item-icon {
        height: 22px;
        min-width: 22px;
        border-radius: 50%;
      }
      .Habout-item-icon ion-icon {
        font-size: 9px;
      }
      .Habout-item-text {
        font-size: 0.7rem;
      }
      .Habout .Hcallout {
        font-size: 0.8rem;
        line-height: 1.4;
        padding: 12px 17px;
        margin-bottom: 17px;
      }
      .Hservice {
        padding-block: 12px;
      }
      .Hservice-list {
        gap: 11px;
      }
      .Hservice-card {
        padding: 4px 5px;
        padding: 1px 3px;
        width: 15vh;
      }
      
      .Hservice-card .Hcard-icon {
        width: max-content;
        margin-inline: start;
        margin-bottom: 1px;

      }
      .Hcard-icon .forex-logo{
        width: 6vh;
        height: 5vh;
      }

     
      .Hservice-card .Hcard-title { 
        font-size: 1.05rem;
        margin-bottom: 6px; 
      }
    
      .Hservice-card .Hcard-text {
        font-size: 0.5rem;
        line-height: 1.7;
        margin-bottom: 16px;
      }
      .Hfeatures {
        padding-block: 22px;
      }
      .Hfeatures-list > li { width: calc(50% - 95px); }
      .Hfeatures-list {
        gap: 11px 6px;
      }
      .Hfeatures-card {
        padding: 4px 3px;
        gap: 11px;
        box-shadow: 0 5px 21px 0 hsla(219, 56%, 22%, 0.1);
      }
      .Hfeatures-card .Hcard-icon {
        width: 22px;
        height: 22px;
        font-size: 14px;
      }
      .Hfeatures-card .Hcard-title {
        font-size: 0.65rem;
        font-weight: 600;
      }
      .Hfeatures-card .Hcard-btn {
        width: 17px;
        height: 17px;
        font-size: 11px;
      }
      .Hcta-card {
        height: 17vh;
        padding: 8px 4px;
        box-shadow: 0 16px 32px hsla(188, 63%, 7%, 0.1);
      }
      .Hcta-card .Hcard-content {
        margin-bottom: 11px;
      }
      .Hcta-card .Hcard-title {
        line-height: 1.1;
        margin-bottom: 8px;
      }
    
      .Hcta-card .Hcard-text {
        font-size: 0.7rem;
        line-height: 1.9;
      }
    
      .Hfooter {
        margin-bottom: 3px;
      }
      .Hfooter-top {
        padding-top: 3px;
        padding-bottom: 2px;
      }
      .Hsection-text {
        display: inline;
        font-size: 0.7rem;
        line-height: 1.7;
        margin-bottom: 11px;
        max-width: 100%;
      }
      .Hcontact-link {
        gap: 7px;
      }
      .Hcontact-link ion-icon { font-size: 11px; }
      .Hcontact-link :is(address, span) {
        font-size: 0.7rem;
      }
      .Hfooter-list-title {
        font-size: 0.9rem;
        font-weight: 700;
        margin-bottom: 6px;
      }
      .Hsocial-list {
        gap: 8px;
      }
      .Hfooter-list:not(:last-child) { margin-bottom: 22px; }
      .Hfooter-link {
        font-size: 0.7rem;
        padding-block: 4px;
      } 
      .Hfooter-bottom {
        padding-block: 2px;
      }
      
      .Hcopyright {
        font-size: 0.5rem;
      }
    }
  }
  @media only screen 
  and (min-device-width: 834px) 
  and (max-device-width: 1366px) 
  and (-webkit-min-device-pixel-ratio: 1.5) {
    .Habout-banner {
      display: none;
    }
    .Hhero-banner .Hw-100{
      position: relative;
      top: 14vh;
      
    }
    .Habout-banner {
      display: none;
    }


}
@media (max-width: 1024px) {
  
  .Habout-banner{display:none;}
  .Hhero-banner .Hw-100{
    position: relative;
    top: 14vh;}
  
    
    .Hheader-bottom-actions {
      background: hsl(0, 0%, 100%);
      width: 100%;
      display: flex;
     align-items: center;
     justify-content: flex-start;
     overflow:auto;
     padding-block: 1px 1px;
     box-shadow: -2px 0 30px hsla(237, 100%, 58%, 0.2);
     z-index: 1;
    }
}







/*-----------------------------------*\
 #HOUSE LIST
\*-----------------------------------*/

.houselist {
  padding: 20px;
}
.back-button{
  color: white;
}
li {
  list-style: none;
}

a {
  text-decoration: none;
}

a,
img,
span,
button,
ion-icon {
  display: block;
}

button {
  border: none;
  background: none;
  font: inherit;
  text-align: left;
  cursor: pointer;
}
.notification-icon{
  color: rgb(255, 255, 255);
  font-weight: 500;
}

.watchlist-icon{
  color: rgb(255, 255, 255);
  
}


address {
  font-style: normal;
}

ion-icon {
  pointer-events: none;
}

html {
  font-family: "Nunito Sans", sans-serif;
  scroll-behavior: smooth;
}

body {
  background: hsl(0, 0%, 100%);
  overflow-x: hidden;
}

button,
a {
  transition: 0.25s ease;
}

.Hh1,
.Hh2,
.Hh3 {
  color: hsl(188, 63%, 7%);
  font-family: "Poppins", sans-serif;
  line-height: 1.3;
}

.Hh1 {
  font-size: 1.875rem;
  line-height: 1;
}

.Hh2 {
  font-size: 1.5rem;
}

.Hh3 {
  font-size: 1.125rem;
  font-weight: 700;
}

.Hh3 > a {
  color: inherit;
}

.Hw-100 {
  position: relative;
  width: 10%;
 
 
}

.Hcard-badge {
  background: hsl(0, 0%, 0%);
  color: hsl(0, 0%, 100%);
  font-size: 0.75rem;
  text-transform: uppercase;
  position: absolute;
  top: 15px;
  right: 15px;
  padding: 4px 10px;
}

.Hcard-badge.green {
  background: hsl(89, 72%, 45%);
}

.Hcard-badge.orange {
  background: hsl(2, 100%, 51%);
}

.note {
  display: flex;
  justify-content: center;
  font-family: cursive;
  font-weight: 900;
  color: rgb(255, 0, 0);
}

.header {
  background-color: hsl(200, 69%, 25%);
  padding: 10px;
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header label {
  color: hsl(0, 0%, 100%);
  font-size: 1rem;
  margin-right: 10px;
}

.header select {
  padding: 5px;
  font-size: 1rem;
}

.hbtn {
  background: hsl(150, 100%, 100%);
  color: hsl(0, 0%, 0%);
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  text-transform: capitalize;
  border: 1px solid hsl(0, 0%, 0%);
  padding: 10px 20px;
  z-index: 1;
}

.hbtn:is(:hover, :focus) {
  background: hsl(240, 2%, 10%);
  color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 100%);
}

.hbtn::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: hsl(0, 0%, 100%);
  transition: 0.25s ease;
  z-index: -1;
}

.hbtn:is(:hover, :focus)::before {
  width: 100%;
}

.Hproperty {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 20px;
  padding-block: 20px;
}

.Hproperty-card {
  
  border: 1px solid hsl(210, 100%, 97%);
  box-shadow: 0 16px 32px hsla(188, 63%, 7%, 0.1);
  overflow: hidden;
}

.Hproperty-card .Hcard-banner {
  position: relative;
  width: 100%;
  height: 200px;
  overflow: hidden;
}

.Hproperty-card .Hcard-banner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.5s ease;
}

.Hproperty-card:hover .Hcard-banner img {
  transform: scale(1.1);
}

.Hproperty-card .Hcard-banner::before {
  content: "";
  position: absolute;
  inset: 0;
  background: linear-gradient(to top, hsla(0, 0%, 0%, 0.95), transparent 30%);
  pointer-events: none;
  z-index: 1;
}

.Hproperty-card .Hbanner-actions {
  position: absolute;
  bottom: 15px;
  left: 15px;
  right: 15px;
  display: flex;
  flex-wrap: wrap-reverse;
  align-items: center;
  gap: 15px 10px;
  z-index: 1;
}

.Hbanner-actions-btn {
  color: hsl(0, 0%, 100%);
  font-size: 0.813rem;
  line-height: 1;
  display: flex;
  align-items: flex-end;
  gap: 4px;
}

.Hbanner-actions-btn ion-icon {
  font-size: 16px;
}

.Hbanner-actions-btn:first-child {
  margin-right: auto;
}

.Hbanner-actions-btn:is(:hover, :focus) {
  color: hsl(128, 94%, 45%);
}

.Hproperty-card .Hcard-content {
  padding: 15px;
  border-bottom: 1px solid hsla(0, 83%, 43%, 0.1);
}

.Hcard-price {
  color: hsl(128, 94%, 45%);
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  margin-bottom: 3px;
}

.Hcard-price strong {
  font-size: 1.125rem;
  font-weight: 600;
}

.Hproperty-card .Hcard-title {
  font-weight: 600;
  margin-bottom: 10px;
}

.HHproperty-card .card-title > a:is(:hover, :focus) {
  color: hsl(128, 94%, 45%);
}

.Hproperty-card .Hcard-text {
  color: hsl(200, 15%, 43%);
  font-size: 0.875rem;
  line-height: 1.8;
  margin-bottom: 15px;
}

.Hproperty-card .Hcard-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  row-gap: 10px;
}

.Hproperty-card .Hcard-item {
  padding-block: 5px;
  color: hsl(200, 15%, 43%);
  font-size: 0.875rem;
}

.Hproperty-card .Hcard-item:not(:last-child) {
  padding-right: 15px;
  border-right: 1px solid hsla(0, 0%, 0%, 0.2);
  margin-right: 20px;
}

.Hproperty-card .Hcard-item :is(strong, ion-icon) {
  display: inline-block;
}

.Hproperty-card .Hcard-item ion-icon {
  margin-left: 2px;
  margin-bottom: -2px;
}

.Hproperty-card .Hcard-item span {
  margin-top: 5px;
}

.Hcard-footer {
  padding: 15px;
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}

.Hcard-footer-actions {
  display: flex;
  align-items: center;
  gap: 10px;
}

.Hcard-footer-actions-btn {
  background: hsl(192, 24%, 96%);
  color: hsl(200, 15%, 43%);
  width: 35px;
  height: 35px;
  display: grid;
  place-items: center;
  font-size: 18px;
}

.Hcard-footer-actions-btn:is(:hover, :focus) {
  background: hsl(128, 94%, 45%);
  color: hsl(0, 0%, 100%);
}

.btn {
  position: relative;
  background: hsl(128, 94%, 45%);
  color: hsl(0, 0%, 100%);
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  text-transform: capitalize;
  border: 1px solid hsl(128, 94%, 45%);
  padding: 10px 20px;
  z-index: 1;
}

.btn:is(:hover, :focus) {
  background: hsl(0, 0%, 0%);
  color: hsl(188, 63%, 7%);
  border-color: hsl(0, 0%, 0%);
}

.btn::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: hsl(0, 0%, 100%);
  transition: 0.25s ease;
  z-index: -1;
}

.btn:is(:hover, :focus)::before {
  width: 100%;
}



@media (max-width: 600px) {
  .houselist {
    padding: 10px;
  }
  .header {
    height: 10vh; /* Header occupies 10% of the screen */
    flex-direction: column;
    align-items: flex-start;
    padding-top: 1px;
  }

  .hbtn {
    font-size: 3px;
    width: 80px;
    height: 17px;
    padding: 2px;
  }
  .hbtn1{
    position: relative;
    left: 12vh;
    top:-59px; 
    font-size: 10px;
  height: 25px;
  width: 100px;
  }
  .hbtn2{
    position: relative;
    left: 23vh;
    top:-60px;
    font-size: 10px;
  height: 25px;
  
  }

  .notification-icon{
  position: relative;
  left: 38vh;
  top:-14vh

  }
  
  .watchlist-icon{
    color: rgb(255, 255, 255);
    position: relative;
    left: 38vh;
    top:-9vh
  }

  .Hproperty {
    grid-template-columns: 1fr 1fr; /* Two columns in mobile view */
    gap: 3px;
    padding-block: 1px;
    width: 20vh;
    padding-inline: 2px;
    row-gap: 10px;

   
  }
  .Hproperty-card{
    display: grid;
    grid-template-columns: repeat( minmax(100px, 1fr));
    gap: 10px;
    padding-block: 2px;
    width: 173px;
    padding-inline: 8px;
    
  }
  .Hproperty-card .Hcard-banner {
    height: 110px;
    width: 23vh; }

  .Hproperty-card .Hcard-content {
    padding: 1px;
  }

  .Hproperty-card .Hcard-text {
    font-size: 0.65rem;
    margin-bottom: 10px;
  }
  .Hcard-price { font-size: 0.8rem;}
  .Hcard-price strong{
    font-size: 0.8rem;
  }

  .Hproperty-card .Hcard-item {
    font-size: 0.75rem;
    margin-right: 10px;
  }

  .Hcard-footer {
    padding: 1px;
   
  }

  .btn {
    font-size: 0.75rem;
    padding: 5px 10px;
  }
}





/*-----------------------------------*\
 #HOUSE DETAILS
\*-----------------------------------*/

.houseid {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  overflow: hidden;
}

.headerD {
  background-color: hsl(200, 69%, 25%);
  padding: 10px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
  padding-right: 45%;
}

.headerD h2 {
  margin: 0;
  font-size: 1.5rem;
  color: hsl(0, 0%, 0%);
}
.back-button{
  color: white;
  
}

.media-slider {
  display: flex;
  overflow: hidden;
  width: 60%;
  height: 100%;
  position: relative;
}

.media-slider-container {
  display: flex;
  transition: transform 0.3s ease-in-out;
  width: 100%;
  height: 100%;
}

.media-slider-container img,
.media-slider-container video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  flex-shrink: 0;
}

.slider-control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 10px;
  cursor: pointer;
  z-index: 2;
}

.slider-control.left {
  left: 10px;
}

.slider-control.right {
  right: 10px;
}

.house-content {
  width: 40%;
  padding: 20px;
  box-sizing: border-box;
  background-color:  hsl(0, 0%, 93%);
  font-family: Arial, sans-serif;
  font-size: 1rem;
  order: 2; 
}

.houseD {
  margin-bottom: 10px;
}

.houseD strong {
  font-size: 1.1rem;
  font-weight: 600;
  color:  hsl(128, 94%, 45%);
}

.Hcard-footer {
  text-align: center;
  margin-top: 20px;
}

.Hcard-footer-actions {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.btn {
  background: hsl(128, 94%, 45%);
  color: hsl(0, 0%, 100%);
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  text-transform: capitalize;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.btn:is(:hover, :focus) {
  background: hsl(128, 84%, 35%);
}

.error-message {
  color: hsl(0, 75%, 50%);
  font-size: 1rem;
  margin-bottom: 10px;
}

@media (max-width: 600px) {
  .houseid {
    flex-direction: column;
    height: 100vh;
    overflow: hidden;
  }

  .headerD {
    order: 1; /* Ensure the header is always on top */
    position: relative;
    margin-bottom: 2px;
  }

  .media-slider {
    width: 100%;
    height: 40%;
    order: 2; /* Position the slider below the header in mobile */
  }

  .media-slider-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
  }

  .media-slider-container img,
  .media-slider-container video {
    width: 100%;
    height: auto;
    object-fit: contain;
  }

  .house-content {
    width: 100%;
    height: 60%;
    order: 3; /* Position content below the slider in mobile */
    overflow-y: auto;
    padding: 15px;
  }

  .headerD h2 {
    font-size: 1.2rem;
  }

  .houseD {
    font-size: 0.9rem;
  }

  .houseD strong {
    font-size: 1rem;
  }

  .btn {
    font-size: 0.875rem;
    padding: 8px 15px;
  }
}





  /*-----------------------------------*\
 #PAYMENT
 \*-----------------------------------*/

 .payment-container {
  max-width: 400px;
  margin: 50px auto;
  padding: 20px;
  border: 1px solid #ffffff;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;

  .payment-container .payment-container h2 {
    margin-bottom: 20px;
    color: #333;
  }
  
  .payment-container p {
    margin-bottom: 10px;
    color: #666;
  }
  
  .payment-container h3 {
    margin-bottom: 20px;
    color: #007bff;
  }
  
  .payment-form {
    display: flex;
    flex-direction: column;
  }
  
  .payment-form label {
    margin-bottom: 5px;
    text-align: left;
    color: #333;
  }
  
  .payment-form input {
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .payment-form button {
    padding: 10px;
    font-family: 'Times New Roman', Times, serif;
    background-color: hsl(128, 94%, 45%);
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .payment-form button:hover {
    background-color: #8bce91;
  }
  .NB p{
    font-family: 'Courier New', Courier, monospace;
    color: red;
  }
  @media (max-width: 600px) {
    .payment-container {
      max-width: 200px;
      margin: 50px auto;
      padding: 10px;
    } 
    .payment-container h2 {
      font-size: 0.7em;
      margin-bottom: 2px;
    }
    .payment-container p {
      margin-bottom: 5px;
      font-size: 0.5em;
    }
    .payment-container h3 {
      margin-bottom: 10px;
      font-size: 0.7em;
    }
    .payment-form label {
      margin-bottom: 5px;
      font-size: 0.64em;
    }
    .payment-form input {
      padding: 5px;
      margin-bottom: 10px;
    }
    
    .payment-form button {
      padding-left: 36%;
      font-size: 0.6em;
    }
    
  
  }
}
  /*-----------------------------------*\
 #ADD HOUSE
 \*-----------------------------------*/
/* AddHouse.css */
.addhouse{
  .container {
    max-width: 600px;
    margin: 0 ;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }
  
  h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  form div {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    color: #555;
  }
  
  input[type="text"],
  input[type="number"],
  textarea,
  select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  input[type="radio"] {
    margin-right: 10px;
  }
  
  button {
    width: 100%;
    padding: 10px;
    background-color: #28a745;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 16px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #218838;
  }
  
  .message {
    text-align: center;
    margin-top: 20px;
    font-size: 16px;
  }
  
  .message.success {
    color: green;
  }
  
  .message.error {
    color: red;
  }
}
   /*-----------------------------------*\
  #PAYMENT PAGE
\*-----------------------------------*/
.payment-page {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
  position: relative;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
  font-size: 24px;
}

.message {
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 4px;
  text-align: center;
  font-size: 16px;
}

.error-message {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

.success-message {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.payment-info-box,
.alert-box {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 20px;
  background-color: #fff;
}

.payment-info-box {
  background-color: #e7f3fe;
  border-color: #b3d7ff;
}

.payment-info-box p strong {
  font-weight: 900;
  color: hsl(128, 94%, 45%);
}

.alert-box {
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
  font-size: 16px;
}

input[type="text"],
input[type="tel"],
input[type="number"] {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
}

.timer {
  text-align: center;
  margin-bottom: 20px;
  font-size: 18px;
  color: #333;
}

button[type="submit"],
button.home-button {
  width: 100%;
  padding: 10px;
  background-color: hsl(128, 94%, 45%);
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

button[type="submit"]:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

button.home-button {
  margin-top: 20px;
  background-color: #007bff;
}

button.home-button:hover {
  background-color: #0056b3;
}

.loading-container {
  margin-top: 20px;
  text-align: center;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #09f;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.confirmation-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  font-size: 24px;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.3s ease-in-out;
}

.confirmation-overlay.visible {
  visibility: visible;
  opacity: 1;
}

.confirmation-overlay p {
  margin-top: 10px;
  font-size: 16px;
  color: #fff;
}

@media (max-width: 600px) {
  .payment-page {
    padding: 10px;
    box-shadow: none;
    border: none;
  }

  h1 {
    font-size: 18px;
  }

  .message {
    font-size: 14px;
  }

  .payment-info-box,
  .alert-box {
    padding: 10px;
    font-size: 14px;
  }

  .form-group label {
    font-size: 14px;
  }

  input[type="text"],
  input[type="tel"],
  input[type="number"] {
    font-size: 14px;
    padding: 6px;
  }

  button[type="submit"],
  button.home-button {
    font-size: 14px;
    padding: 8px;
  }

  .timer {
    font-size: 16px;
  }

  .loading-spinner {
    width: 30px;
    height: 30px;
  }

  p {
    font-size: 14px;
  }
}




 /*-----------------------------------*\
 PROFILE
 \*-----------------------------------*/

 .profile-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .profile-popup {
    background: white;
    padding: 20px;
    border-radius: 5px;
    width: 300px;
    text-align: center;
    position: relative;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
  }
  
}



  /*-----------------------------------*\
   #ATTENDANCE PAGE
 \*-----------------------------------*/
 .attendance-page {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
  font-family: Arial, sans-serif;

  h1 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .attendance-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .attendance-table th,
  .attendance-table td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: center;
    font-size: 16px;
  }
  
  .attendance-table th {
    background-color: #f1f1f1;
    color: #333;
    font-weight: bold;
  }
  
  .attendance-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .attend-button, .attended-button {
    padding: 8px 12px;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
    margin-right: 5px;
  }
  
  .attend-button {
    background-color: #ffc107;
  }
  
  .attend-button:hover {
    background-color: #e0a800;
  }
  
  .attended-button {
    background-color: #007bff;
  }
  
  .attended-button:hover {
    background-color: #0069d9;
  }
  
  .attending-status {
    font-size: 14px;
    color: #007bff;
  }
  
  .attended-status {
    font-size: 14px;
    color: #28a745;
  }
}




  /*-----------------------------------*\
   #ADMINSELECTION PAGE
 \*-----------------------------------*/


 .admin-selection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f9f9f9;
  font-family: Arial, sans-serif;


  h1 {
    margin-bottom: 40px;
    color: #333;
    font-size: 24px;
  }
  
  .selection-buttons {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .selection-button {
    padding: 15px 25px;
    font-size: 18px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .selection-button:hover {
    background-color: #0056b3;
  }
}


.admin-confirmation {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
  font-family: Arial, sans-serif;

  h1 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .payment-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .payment-table th,
  .payment-table td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: center;
    font-size: 16px;
  }
  
  .payment-table th {
    background-color: #f1f1f1;
    color: #333;
    font-weight: bold;
  }
  
  .payment-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .confirm-button, .not-confirmed-button {
    padding: 8px 12px;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
  }
  
  .confirm-button {
    background-color: #28a745;
  }
  
  .confirm-button:hover {
    background-color: #218838;
  }
  
  .not-confirmed-button {
    background-color: #dc3545;
  }
  
  .not-confirmed-button:hover {
    background-color: #c82333;
  }
}
 
.terms{
  max-width: 150vh;
  margin-left: 2vh;
  
}
.terms p h1 {font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;}

@media (max-width: 600px) {
  .terms{
    margin-left:1px;
  }
}
.web-services-container, .forex-page-container, .second-hand-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

header {
  text-align: center;
  padding: 50px 0;
  background-color: #f4f4f4;
}

h1 {
  font-size: 36px;
  color: #333;
}

.web-services-content, .forex-content, .second-hand-content {
  margin-top: 20px;
}

h2 {
  font-size: 28px;
  color: #555;
}

p, ol, a {
  font-size: 18px;
  color: #666;
  line-height: 1.6;
}

a.forex-link {
  display: inline-block;
  margin-top: 15px;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
}

.buy-section, .sell-section {
  margin-bottom: 40px;
}

button.buy-button, button.sell-button {
  padding: 10px 20px;
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}


  /*-----------------------------------*\
   #Webservices
 \*-----------------------------------*/

 .web-services-container {
  font-family: 'Arial, sans-serif';
  color: #333;
}

.web-services-banner {
  background: url("./LPimages/blue2.jpeg"); /* Add your hero image here */
  background-size: cover;
  background-position: center;
  text-align: center;
  padding: 50px 20px;
  color: #fff;
}

.web-services-banner h1 {
  font-size: 3rem;
  margin: 0;
}

.web-services-content {
  background-color: #f4f4f4;
  padding: 20px;
  text-align: center;
}

.web-services-content p {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.web-services-content h2 {
  color:  hsl(128, 94%, 45%);
}

.contact-info {
  display: flex;
  justify-content: center;
  gap: 40px;
  margin-top: 20px;
}

.contact-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.contact-item img {
  width: 24px;
  height: 24px;
}

.contact-item p {
  margin: 0;
}

.contact-item a {
  color:  hsl(128, 94%, 45%);
  text-decoration: none;
}

.contact-item a:hover {
  text-decoration: underline;
}
@media (max-width: 1024px) { /* iPad and below */
  .web-services-banner h1 {
    font-size: 2rem;
  }

  .web-services-content p {
    font-size: 0.9rem;
  }

  .web-services-content h2 {
    font-size: 1.3rem;
  }
}

@media (max-width: 768px) { /* Mobile Phones */
  .web-services-banner {
    padding: 30px 15px;
  }

  .web-services-banner h1 {
    font-size: 1.8rem;
  }

  .web-services-content {
    padding: 15px;
  }

  .web-services-content p {
    font-size: 0.85rem;
  }

  .web-services-content h2 {
    font-size: 1.2rem;
  }
}




/* Shared Styles for Logo and Video Services */
.services-banner {
  background-color:  hsl(128, 94%, 45%);
  color: white;
  padding: 50px 20px;
  text-align: center;
}
 
.services-banner h1 {
  font-size: 2.5rem;
  margin: 0;
}

.services-content {
  margin-top: 20px;
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.services-content p {
  font-size: 1rem;
  line-height: 1.6;
  color: #333;
}

.services-content h2 {
  margin-top: 20px;
  font-size: 1.5rem;
  color:  hsl(128, 94%, 45%);
}

/* Responsive Styles for Logo and Video Services */
@media (max-width: 1024px) { /* iPad and below */
  .services-banner h1 {
    font-size: 2rem;
  }

  .services-content p {
    font-size: 0.9rem;
  }

  .services-content h2 {
    font-size: 1.3rem;
  }
}

@media (max-width: 768px) { /* Mobile Phones */
  .services-banner {
    padding: 30px 15px;
  }

  .services-banner h1 {
    font-size: 1.8rem;
  }

  .services-content {
    padding: 15px;
  }

  .services-content p {
    font-size: 0.85rem;
  }

  .services-content h2 {
    font-size: 1.2rem;
  }
}

}

